import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import ContasList from "../ContasList";
import NovaContaModal from "../NovaContaModal";
import jsPDF from "jspdf";

const Dashboard = ({ setAuthenticated }) => {
  const [contas, setContas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalAberto, setModalAberto] = useState(false);
  const [modalRelatorioAberto, setModalRelatorioAberto] = useState(false);
  const [intervalo, setIntervalo] = useState("30"); // Intervalo inicial
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContas = async () => {
      const token = localStorage.getItem("authToken");

      if (!token) {
        navigate("/"); // Redireciona para login se o token não existir
        return;
      }

      try {
        const response = await api.get("/contas", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setContas(response.data);
      } catch (error) {
        console.error("Erro ao buscar contas:", error);
        localStorage.removeItem("authToken"); // Remove o token inválido
        setAuthenticated(false); // Logout ao encontrar erro
        navigate("/"); // Redireciona para login
      } finally {
        setIsLoading(false);
      }
    };

    fetchContas();
  }, [navigate, setAuthenticated]);

  const handleNovaConta = async (novaConta) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await api.post("/contas", novaConta, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setContas((prevContas) => [...prevContas, response.data]); // Atualiza as contas na tela
    } catch (error) {
      console.error("Erro ao criar conta:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    setAuthenticated(false); // Atualiza o estado de autenticação
    navigate("/"); // Redireciona para a página de login
  };

  const gerarRelatorio = async () => {
    try {
      const token = localStorage.getItem("authToken");

      if (!token) {
        navigate("/"); // Redireciona para login se o token não existir
        return;
      }

      const response = await api.get("/contas", {
        headers: { Authorization: `Bearer ${token}` },
      });

      const contasPagas = response.data.filter((conta) => conta.status); // Filtra apenas contas pagas

      const doc = new jsPDF("p", "mm", "a4");
      doc.setFontSize(14);

      // Cabeçalho do relatório
      doc.text("Relatório de Contas Pagas", 105, 15, { align: "center" });
      doc.setFontSize(10);
      const dataAtual = new Date().toLocaleDateString("pt-BR");
      doc.text(`Data de emissão: ${dataAtual}`, 105, 25, { align: "center" });

      // Adiciona cabeçalhos da tabela
      doc.setFontSize(10);
      doc.text("Descrição", 20, 40);
      doc.text("Data de Pagamento", 100, 40);
      doc.text("Valor (R$)", 170, 40, { align: "right" });

      doc.line(20, 42, 190, 42); // Linha horizontal abaixo dos cabeçalhos

      // Preenchendo os dados da tabela
      let y = 50; // Posição inicial para os dados
      contasPagas.forEach((conta) => {
        if (y > 280) {
          // Adiciona nova página se ultrapassar o limite
          doc.addPage();
          y = 20;
        }

        const dataFormatada = new Date(conta.data_pagamento).toLocaleDateString(
          "pt-BR"
        );
        doc.text(conta.descricao || "-", 20, y);
        doc.text(dataFormatada, 100, y);
        doc.text(conta.valor, 170, y, { align: "right" });

        y += 10; // Incrementa a posição vertical
      });

      // Rodapé do relatório
      const totalPago = contasPagas.reduce(
        (acc, conta) => acc + parseFloat(conta.valor || 0),
        0
      );

      doc.setFontSize(12);
      doc.line(20, y + 5, 190, y + 5); // Linha horizontal acima do total
      doc.text(`Total Pago: R$ ${totalPago}`, 170, y + 15, {
        align: "right",
      });

      // Salvar o arquivo PDF
      doc.save("relatorio_contas_pagas.pdf");
    } catch (error) {
      console.error("Erro ao gerar relatório:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-lg text-gray-700">Carregando...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 text-white p-4 shadow-lg flex justify-between items-center">
        <h1 className="text-2xl font-bold">Dashboard</h1>
        <div className="flex gap-4">
          {/* Botão Nova Conta */}
          <button
            onClick={() => setModalAberto(true)}
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105"
          >
            <span className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
              Nova Conta
            </span>
          </button>

          {/* Botão Sair */}
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105"
          >
            <span className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7"
                />
              </svg>
              Sair
            </span>
          </button>

          {/* Botão Gerar Relatório */}
          <button
            onClick={() => setModalRelatorioAberto(true)}
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105"
          >
            <span className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 4h12v16H4zM16 6h4v12h-4zM8 10h4m-4 4h4"
                />
              </svg>
              Gerar Relatório
            </span>
          </button>
        </div>
      </header>
      <main className="p-6">
        <ContasList contas={contas} />
      </main>

      {modalAberto && (
        <NovaContaModal
          onClose={() => setModalAberto(false)}
          onSubmit={handleNovaConta}
        />
      )}

      {modalRelatorioAberto && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">
              Selecione o Intervalo de Tempo
            </h2>
            <div className="mb-4">
              <label className="block mb-2">Escolha o intervalo:</label>
              <select
                value={intervalo}
                onChange={(e) => setIntervalo(e.target.value)}
                className="border rounded px-4 py-2 w-full"
              >
                <option value="30dias">Últimos 30 dias</option>
                <option value="60dias">Últimos 60 dias</option>
                <option value="90dias">Últimos 90 dias</option>
                <option value="ano">Ano inteiro</option>
                <option value="data_personalizada">Data personalizada</option>
              </select>
            </div>

            {intervalo === "data_personalizada" && (
              <div>
                <label className="block mb-2">Data de Início:</label>
                <input
                  type="date"
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e.target.value)}
                  className="border rounded px-4 py-2 w-full"
                />
                <label className="block mt-4 mb-2">Data de Fim:</label>
                <input
                  type="date"
                  value={dataFim}
                  onChange={(e) => setDataFim(e.target.value)}
                  className="border rounded px-4 py-2 w-full"
                />
              </div>
            )}

            <div className="flex justify-end gap-4">
              <button
                onClick={() => setModalRelatorioAberto(false)}
                className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded-lg"
              >
                Cancelar
              </button>
              <button
                onClick={gerarRelatorio}
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
              >
                Gerar Relatório
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
