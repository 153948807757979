import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../Recayd_Contas-removebg-preview.png";

const Login = ({ setAuthenticated }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://backend-contas-production.up.railway.app/login",
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        localStorage.setItem("authToken", response.data.token);
        setAuthenticated(true);
        navigate("/dashboard");
      }
    } catch (err) {
      setError(
        err.response?.status === 401
          ? "Credenciais inválidas. Tente novamente."
          : "Erro ao tentar login. Tente novamente mais tarde."
      );
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Banner lateral */}
      <div className="hidden md:flex flex-col justify-center items-center bg-gradient-to-r from-green-200 via-green-100 to-white text-white w-1/2 p-8">
        <h1 className="text-4xl text-green-800 font-bold mb-6">
          Bem-vindo ao Recayd Contas!
        </h1>
        <p className="text-xl mb-4 text-green-800 font-bold text-center">
          Organize suas contas de forma simples e prática. Nosso sistema ajuda
          você a manter tudo em dia e melhorar sua gestão financeira.
        </p>
        <img src={Logo} alt="Ilustração de finanças" className=" " />
      </div>

      {/* Formulário de Login */}
      <div className="flex flex-col justify-center items-center w-full md:w-1/2 p-8 bg-gradient-to-l from-green-200 via-green-100 to-white">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
            Faça login na sua conta
          </h2>

          {error && (
            <p className="text-red-500 text-sm text-center mb-4">{error}</p>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Digite seu email"
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Senha
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Digite sua senha"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Entrar
            </button>
          </form>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">Ainda não tem uma conta?</p>
            <Link to="/register">
              <button
                type="button"
                className="mt-2 w-full bg-gray-100 text-blue-600 border border-blue-600 py-2 px-4 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Criar Conta
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
