import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./componentes/Registro";
import Login from "./componentes/Login";
import Dashboard from "./componentes/Dashboard";

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken"); // Verifica se o token está presente no localStorage
    if (token) {
      setAuthenticated(true); // Se houver token, considera o usuário autenticado
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              authenticated ? (
                <Dashboard setAuthenticated={setAuthenticated} />
              ) : (
                <Login setAuthenticated={setAuthenticated} />
              )
            }
          />
          <Route path="/register" element={<Register />} />
          <Route
            path="/dashboard"
            element={
              authenticated ? (
                <Dashboard setAuthenticated={setAuthenticated} />
              ) : (
                <Login setAuthenticated={setAuthenticated} />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
