import React, { useState, useEffect } from "react";
import api from "../../api"; // Importe a instância do axios configurada

const ContasList = ({ contas }) => {
  const [abaAtiva, setAbaAtiva] = useState("a_vencer");
  const [contaSelecionada, setContaSelecionada] = useState(null);
  const [comprovante, setComprovante] = useState(null);
  const [dataPagamento, setDataPagamento] = useState("");
  const [modalAberta, setModalAberta] = useState(false);
  const [tipoModal, setTipoModal] = useState(null);
  const [contass, setContass] = useState([]);
  // Função para filtrar as contas com base no status
  const contasFiltradas = (status) => {
    const hoje = new Date();
    if (status === "pagas") {
      return contas.filter((conta) => conta.status); // Contas pagas
    }
    if (status === "vencidas") {
      return contas.filter(
        (conta) => !conta.status && new Date(conta.data_vencimento) < hoje // Contas vencidas
      );
    }
    if (status === "a_vencer") {
      return contas.filter(
        (conta) => !conta.status && new Date(conta.data_vencimento) >= hoje // Contas a vencer
      );
    }
    return [];
  };
  const baixarComprovante = async (id) => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      alert("Token não encontrado. Faça login novamente.");
      return;
    }

    try {
      const response = await api.get(`/contas/${id}/comprovante`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Indica que a resposta será um arquivo binário
      });

      // Cria um link de download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `comprovante_${id}.pdf`); // Nome do arquivo
      document.body.appendChild(link);
      link.click();

      // Limpa o link após o clique
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao baixar comprovante:", error);
      alert("Erro ao baixar comprovante.");
    }
  };

  // Função para abrir a modal de dar baixa
  const abrirModal = (conta, tipo) => {
    setContaSelecionada(conta);
    setTipoModal(tipo);
    setModalAberta(true);
  };

  // Função para fechar a modal
  const fecharModal = () => {
    setContaSelecionada(null);
    setComprovante(null);
    setDataPagamento("");
    setModalAberta(false);
  };
  const handleBaixaConta = async () => {
    if (!comprovante || !dataPagamento) {
      alert("Comprovante e data de pagamento são obrigatórios!");
      return;
    }

    const formData = new FormData();
    formData.append("comprovante", comprovante);
    formData.append("data_pagamento", dataPagamento);

    // Get the token
    const token = localStorage.getItem("authToken");

    if (!token) {
      alert("Token não encontrado. Faça login novamente.");
      return;
    }

    try {
      // Send the PUT request with the token in the header
      const response = await api.put(
        `/contas/${contaSelecionada.id}/pagar`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Conta baixada com sucesso!");

        // Update the list of accounts locally
        const novasContas = contas.map((conta) =>
          conta.id === contaSelecionada.id
            ? { ...conta, status: true } // Mark the account as paid
            : conta
        );
        setContass(novasContas); // Update the accounts state

        fecharModal(); // Close the modal after success
      }
    } catch (error) {
      console.error("Erro ao dar baixa na conta:", error);
      alert("Erro ao dar baixa na conta.");
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        Contas a Pagar
      </h2>

      {/* Navegação entre as abas */}
      <div className="flex space-x-4 mb-4">
        {["a_vencer", "vencidas", "pagas"].map((aba) => (
          <button
            key={aba}
            onClick={() => setAbaAtiva(aba)}
            className={`py-2 px-4 rounded-lg ${
              abaAtiva === aba
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-800 hover:bg-gray-300"
            }`}
          >
            {aba === "a_vencer"
              ? "A Vencer"
              : aba === "vencidas"
              ? "Vencidas"
              : "Pagas"}
          </button>
        ))}
      </div>

      {/* Tabela com as contas filtradas */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300 text-left">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="border border-gray-300 p-2">Descrição</th>
              <th className="border border-gray-300 p-2">Vencimento</th>
              <th className="border border-gray-300 p-2">Valor</th>
              <th className="border border-gray-300 p-2">Status</th>
              <th className="border border-gray-300 p-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {contasFiltradas(abaAtiva).length > 0 ? (
              contasFiltradas(abaAtiva).map((conta) => (
                <tr key={conta.id} className="hover:bg-gray-100">
                  <td className="border border-gray-300 p-2">
                    {conta.descricao}
                  </td>
                  <td className="border border-gray-300 p-2">
                    {new Date(conta.data_vencimento).toLocaleDateString(
                      "pt-BR"
                    )}
                  </td>
                  <td className="border border-gray-300 p-2">
                    R$ {conta.valor}
                  </td>
                  <td
                    className={`border border-gray-300 p-2 ${
                      conta.status ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {conta.status ? "Paga" : "Pendente"}
                  </td>
                  <td className="border border-gray-300 p-2">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => abrirModal(conta, "detalhes")}
                        className="bg-blue-500 text-white py-1 px-2 rounded-lg hover:bg-blue-600"
                      >
                        Ver Detalhes
                      </button>
                      {conta.status === false && (
                        <button
                          onClick={() => abrirModal(conta, "baixa")}
                          className="bg-green-500 text-white py-1 px-2 rounded-lg hover:bg-green-600"
                        >
                          Dar Baixa
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center text-gray-500 p-4">
                  Nenhuma conta encontrada.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal para dar baixa na conta */}
      {modalAberta && contaSelecionada && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            {tipoModal === "detalhes" ? (
              <>
                <h2 className="text-xl font-bold mb-4">Detalhes da Conta</h2>
                <p>
                  <strong>Descrição:</strong> {contaSelecionada.descricao}
                </p>
                <p>
                  <strong>Vencimento:</strong>{" "}
                  {new Date(
                    contaSelecionada.data_vencimento
                  ).toLocaleDateString("pt-BR")}
                </p>
                <p>
                  <strong>Valor:</strong> R$ {contaSelecionada.valor}
                </p>
                <p>
                  <strong>Status:</strong>{" "}
                  {contaSelecionada.status ? "Paga" : "Pendente"}
                </p>
                {/* Link para visualizar e baixar o comprovante */}
                {contaSelecionada.status && contaSelecionada.comprovante && (
                  <div className="mt-4">
                    <button
                      onClick={() => baixarComprovante(contaSelecionada.id)}
                      className="text-blue-600 hover:underline"
                    >
                      Ver Comprovante
                    </button>
                  </div>
                )}
                <button
                  onClick={fecharModal}
                  className="bg-gray-400 text-white py-1 mt-5 px-4 rounded-lg hover:bg-gray-500"
                >
                  Fechar
                </button>
              </>
            ) : (
              <>
                <h2 className="text-xl font-bold mb-4">Dar Baixa na Conta</h2>
                <form>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Data de Pagamento:
                    <input
                      type="date"
                      value={dataPagamento}
                      onChange={(e) => setDataPagamento(e.target.value)}
                      className="w-full border-gray-300 rounded-lg"
                      required
                    />
                  </label>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Comprovante de Pagamento:
                    <input
                      type="file"
                      onChange={(e) => setComprovante(e.target.files[0])}
                      className="w-full border-gray-300 rounded-lg"
                      required
                    />
                  </label>
                  <div className="flex justify-end space-x-4 mt-4">
                    <button
                      type="button"
                      onClick={fecharModal}
                      className="bg-gray-400 text-white py-1 px-4 rounded-lg hover:bg-gray-500"
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      onClick={handleBaixaConta}
                      className="bg-green-500 text-white py-1 px-4 rounded-lg hover:bg-green-600"
                    >
                      Confirmar Baixa
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContasList;
