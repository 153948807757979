import axios from "axios";

// Instância do axios configurada com a URL da sua API
const api = axios.create({
  baseURL: "https://backend-contas-production.up.railway.app",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
